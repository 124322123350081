<template>
  <div>
    <SeccionCasillaEscritorio v-if="anchoPantalla >= 992" />
    <SeccionCasillaMovil v-else />
  </div>
</template>

<script>
import SeccionCasillaEscritorio from '@/components/Escritorio/vistas/SeccionCasilla.vue';
import SeccionCasillaMovil from '@/components/Movil/vistas/VotosPorCandidaturas/DetalleDeCasilla.vue';

export default {
  name: 'SeccionCasilla',
  components: {
    SeccionCasillaEscritorio,
    SeccionCasillaMovil,
  },
  computed: {
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Secciones',
  },
};
</script>

<style lang="scss" scoped>

</style>
